*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

a {
  text-decoration: none;
  color: $dark-900;
}

ul {
  list-style: none;
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.--just-space {
  justify-content: space-between;
}

.--just-center {
  justify-content: center;
}

.--just-end {
  justify-content: flex-end;
}

.--align-str {
  align-items: stretch;
}

.--align-center {
  align-items: center;
}

.--align-end {
  align-items: flex-end;
}

.--dir-col {
  flex-direction: column;
}

html {
  font-size: 16px;
}

html,
body {
  min-height: 100%;
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

body {
  overflow-x: hidden;
  font-size: 1rem;
}



.container {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  @media (min-width: 320px) {
    max-width: 300px;
  }
  @media (min-width: 376px) {
    max-width: 343px;
  }
  @media (min-width: 481px) {
    max-width: 449px;
  }
  @media (min-width: 641px) {
    max-width: 575px;
  }
  @media (min-width: 745px) {
    max-width: 680px;
  }
  @media (min-width: 961px) {
    max-width: 896px;
  }
  @media (min-width: 1201px) {
    max-width: 1160px;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.94px;
  border-radius: 4px;
  @include transition;
}

.blue-button {
  background-color: #074CE7;
  color: #fff;
  &:hover {
    background-color: #3970EC;
  }
}

input {
  border: none;
  &:focus {
    border: none;
    outline: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul, li, ol {
  margin: 0;
  padding: 0;
}

.dropdown-toggle::after {
  content: none;
}

.btn-secondary {
  background: none;
}
.btn.show {
  background: none;
}

.btn:first-child:active {
  background: none;
}

.btn:hover {
  background: none;
}

.dropdown-item {
  cursor: pointer;
}