@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/base/_mixins.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/base/_variables.scss";

@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/global/_fonts.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/global/_global.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/global/_svg.scss";

@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/_local-fonts.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/base/_mixins.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/base/_variables.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/global/_fonts.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/global/_global.scss";
@import "/Users/hdream/Documents/GitHub/swed-auto/src/scss/global/_svg.scss";

@import "/Users/hdream/Documents/GitHub/swed-auto/src/components/bem-blocks/text-page/style.scss";


//HEADER//
.header-search {
  display: flex;
  position: relative;

  &__btn {
    padding: 4px;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    img {
      height: 24px;
      width: 24px;
      display: block;
    }
  }
}
.header {
  background-color: #ffffff;
  // display: none;
  padding: 0;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  position: relative;
  z-index: 999;
  @media (max-width: 480px) {
    .header-search {
      display: none;
    }
  }
  &__top-line {
    padding: 8px 38px 8px 16px;
    position: relative;
    margin: 0 auto;
    // padding-right: 22px;
    border-bottom: 1px solid #e9e9e9;
    // &::after {
    //     position: absolute;
    //     content: "";
    //     background-color: #E9E9E9;
    //     width: 100vw;
    //     height: 1px;
    //     bottom: 0;
    //     left: calc((-100vw + 1408px)/2);
    //     @media (max-width: 1199px) {
    //         left: calc((-100vw + 960px)/ 2);
    //     }
    //     @media (max-width: 991px) {
    //         left: calc((-100vw + 720px)/ 2);
    //     }
    // }
    @media (max-width: 1200px) {
      padding: 8px 16px 8px 16px;
    }
    @media (max-width: 640px) {
      display: none;
    }
    &__location {
      svg {
        width: 24px;
        height: 24px;
      }
      p {
        font-size: 12px;
        line-height: 14.52px;
        margin-left: 8px;
        color: $dark-900;
      }
    }
    &__nav-list {
      &__item {
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 12px;
          line-height: 14.52px;
          padding: 8px 12px;
          color: $dark-900;
          @include transition;
          border-radius: 4px;
          &:hover {
            color: #074ce7;
          }
          &.active {
            background-color: #e6edfd;
            color: #074ce7;
          }
          &:active {
            background-color: #e6edfd;
            color: #074ce7;
          }
        }
      }
    }
  }
  &__bottom-line {
    padding: 12px 16px;
    position: relative;
    margin: 0 auto;
    @media (max-width: 640px) {
      padding: 38px 16px 24px;
    }

    &__search-sec {
      &__logo {
        margin-right: 22px;
        width: 102px;
        height: 24px;
        @media (max-width: 1200px) {
          width: 85px;
          height: 20px;
        }
        svg {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &__catalog-btn {
        margin-right: 12px;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        padding: 16px 20px;
        cursor: pointer;
        @include transition;
        .catalog-active {
          display: none;
          width: 24px;
          height: 24px;
          @include transition;
          @media (max-width: 1200px) {
            display: none !important;
          }
        }
        .catalog-icon {
          @include transition;
        }
        @media (max-width: 1200px) {
          padding: 16.5px 22px;
          svg {
            display: none;
          }
        }
        @media (max-width: 745px) {
          display: none;
        }
        &:hover {
          background-color: #e7eefd;
          border: 1px solid #e7eefd;
          p {
            color: #074ce7;
          }

          .catalog-active {
            display: block;
          }
          .catalog-icon {
            display: none;
          }
        }

        &.active {
          border: 1px solid #e7eefd;
          p {
            color: #074ce7;
          }

          .catalog-active {
            display: block;
          }
          .catalog-icon {
            display: none;
          }
        }
        &:active {
          border: 1px solid #e7eefd;
          p {
            color: #074ce7;
          }

          .catalog-active {
            display: block;
          }
          .catalog-icon {
            display: none;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        p {
          color: $dark-900;
          font-weight: 500;
          font-size: 14px;
          line-height: 16.94px;
        }
      }
      &__search-line {
        border-radius: 4px;
        padding: 16px 12px 16px 48px;
        width: 360px;
        background-color: #f8fafc;
        color: $dark-900;
        @media (max-width: 1090px) {
          width: 282px;
        }
        @media (max-width: 961px) {
          width: 282px;
          margin-right: 11px;
        }
        @media (max-width: 640px) {
          width: 100%;
        }
        @media (max-width: 480px) {
          display: none;
        }
        &::placeholder {
          font-size: 14px;
          line-height: 16.94px;
          color: #77777c;
          background-color: #f8fafc;
        }
      }
    }
    &__nav {
      .menu-button {
        display: none;
        @media (max-width: 960px) {
          display: flex;
        }
      }
      &__menu {
        &__tablet-wrapper {
          @media (max-width: 960px) {
            display: none;
          }
        }
        &-item {
          &:last-child {
            a {
              margin-right: 0;
            }
          }
          a {
            font-size: 14px;
            line-height: 16.94px;
            margin-right: 12px;
            padding: 4px 8px;
            border-radius: 4px;
            color: $dark-900;
            @include transition;

            &:hover {
              color: #074ce7;
            }
            &.active {
              color: #074ce7;
            }
            @media (max-width: 1000px) {
              margin-right: 6px;
              padding: 4px 6px;
            }
          }
        }
        &-spec-item {
          svg {
            width: 24px;
            height: 24px;
            @media (max-width: 640px) {
              width: 34px;
              height: 34px;
            }
          }
          cursor: pointer;
          @media (max-width: 1200px) {
            margin-right: 8px;
          }

          a,
          button {
            border: 1px solid #e9e9e9;
            border-radius: 5px;
            padding: 0;
            @include transition;
            @media (max-width: 1200px) {
              padding: 12px;
              margin: 0;
            }
            @media (max-width: 640px) {
              border: none;
              padding: 0;
            }
          }
          @media (max-width: 1300px) {
            span {
              display: none;
            }
          }
          &:last-child {
            @media (max-width: 1200px) {
              margin-right: 0;
            }
            @media (max-width: 640px) {
              display: none;
            }
            .user-active {
              display: none;
              width: 24px;
              height: 24px;
            }

            a {
              padding: 16px 20px;
              @media (max-width: 1300px) {
                padding: 12px;
              }

              svg {
                width: 24px;
                height: 24px;
                margin-right: 5px;
                @media (max-width: 1300px) {
                  margin-right: 0;
                }
              }
              &.active {
                border: 1px solid #e7eefd;

                span {
                  color: #074ce7;
                }
                .user-active {
                  display: block;
                }
                .user_icon {
                  display: none;
                }
              }
              &:hover {
                border: 1px solid #e7eefd;
                background-color: #e7eefd;
                span {
                  color: #074ce7;
                }
                .user-active {
                  display: block;
                }
                .user_icon {
                  display: none;
                }
              }
            }
          }
        }
        .spec-item-fav {
          margin-right: 8px;
          margin-left: 21px;
          @media (max-width: 1200px) {
            margin-left: 0;
          }
          @media (max-width: 640px) {
            display: none;
          }
          .fav-active {
            display: none;
            width: 24px;
            height: 24px;
          }

          a {
            padding: 16px;
            // display: block;
            margin-right: 0;
            @media (max-width: 1300px) {
              padding: 12px;
            }
            &.active {
              .fav_icon {
                display: none;
              }
              .fav-active {
                display: block;
              }
              border: 1px solid #e7eefd;
            }

            svg {
              width: 24px;
              height: 24px;
            }
            &:active {
              .fav_icon {
                display: none;
              }
              .fav-active {
                display: block;
              }
              border: 1px solid #e7eefd;
            }
            &:hover {
              .fav_icon {
                display: none;
              }
              .fav-active {
                display: block;
              }
              border: 1px solid #e7eefd;

              background-color: #e7eefd;
            }
          }
        }
      }
    }
  }
}

.v-pagination {
  margin: 48px auto 0;
  width: fit-content;

  .pagination {
    width: fit-content;
    .page-item {
      border: 1px solid #1c1c24;
      &.pagination-active {
        background-color: #074ce7;
        color: #ffffff;
        border-color: #074ce7;
      }
      margin-right: 8px;
      border-radius: 8px;
      color: #1c1c24;
      &:hover {
        border-color: #074ce7;
      }
      &:focus {
        box-shadow: none !important;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

//FOOTER

.footer {
  margin-top: 64px;
  padding: 64px 0;
  background-color: #33333a;
  .container {
    padding: 32px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    @media (max-width: 375px) {
      padding: 24px;
    }
  }
  &__top-line {
    display: grid;
    grid-template-columns: 230px 160px 160px auto;
    grid-gap: 40px;
    @media (max-width: 1200px) {
      grid-gap: 32px;
    }
    @media (max-width: 960px) {
      grid-template-columns: repeat(2, 213px);
      grid-gap: 0;
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &__logo {
      @media (max-width: 960px) {
        grid-column: span 2;
        margin-bottom: 40px;
      }
      @media (max-width: 480px) {
        grid-column: span 1;
      }
    }
    &__logo-img {
      .logo_icon {
        width: 119px;
        height: 28px;
      }
    }
    &__column {
      &:nth-child(3) {
        @media (max-width: 960px) {
          margin-left: 113px;
        }
        @media (max-width: 640px) {
          margin-left: 21px;
        }
        @media (max-width: 480px) {
          margin-left: 0;
          margin-top: 40px;
        }
      }
      &__title {
        color: rgba(27, 24, 25, 0.6);
        font-weight: 500;
        font-size: 12px;
        line-height: 16.8px;
        text-transform: uppercase;
      }
      &__items {
        margin-top: 24px;
        &-link {
          color: $dark-900;
          font-size: 16px;
          line-height: 22.4px;
          margin-top: 8px;
          display: block;
          @include transition;
          &:hover {
            color: #606066;
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    &__last-sec {
      justify-self: end;
      @media (max-width: 960px) {
        justify-self: start;
        margin-top: 71px;
      }
      @media (max-width: 480px) {
        margin-top: 32px;
      }
      @media (max-width: 375px) {
        width: 100%;
      }
      &__button {
        background-color: #074ce7;
        @media (max-width: 960px) {
          width: 280px;
        }
        @include transition;
        &:hover {
          background-color: #3970ec;
        }
        @media (max-width: 375px) {
          width: 100%;
        }
      }
      &__info-links {
        margin-top: 16px;
        &__link {
          color: $dark-900;
          font-weight: 500;
          font-size: 12px;
          line-height: 15.6px;
          margin-top: 4px;
          display: block;
          text-align: end;
          @media (max-width: 960px) {
            text-align: start;
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  &__contact-info__socials {
    &__link {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background-color: #074ce7;
      &:last-child {
        margin-right: 0;
      }
      .tg {
        width: 18px;
        height: 14px;
      }
      .wa {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__contact-info {
    display: grid;
    grid-template-columns: 230px 160px 160px 164px;
    grid-gap: 40px;
    margin-top: 100px;
    @media (max-width: 960px) {
      grid-template-columns: 227px 159px 129px;
      margin-top: 40px;
    }
    @media (max-width: 744px) {
      grid-template-columns: 151px 159px 129px;
    }
    @media (max-width: 744px) {
      grid-template-columns: 101px 131px 129px;
      grid-gap: 24px;
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px;
    }
    &__text {
      color: $dark-900;
      font-weight: 600;
      font-size: 12px;
      line-height: 16.8px;
      @media (max-width: 480px) {
        max-width: 142px;
        &:last-child {
          max-width: 120px;
        }
      }
    }
  }
}

//INTRO

.main-intro {
  padding: 49px 0 69px;
  // position: relative;
  @media (max-width: 960px) {
    padding: 48px 0 63px;
  }
  @media (max-width: 640px) {
    padding: 275px 0 42px;
  }
  @media (max-width: 375px) {
    padding-top: 275px;
  }
  &__title {
    color: $dark-900;
    font-family: "Montserrat Alternates";
    font-size: 64px;
    line-height: 70.4px;
    max-width: 686px;
    font-weight: 500;
    color: $dark-900;
    @media (max-width: 1200px) {
      font-size: 48px;
      line-height: 52.4px;
    }
    @media (max-width: 960px) {
      font-size: 32px;
      line-height: 35.2px;
      max-width: 324px;
    }
    @media (max-width: 640px) {
      max-width: 100%;
    }
    @media (max-width: 480px) {
      line-height: 40px;
      font-weight: 600;
    }
  }
  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    max-width: 460px;
    color: #333;
    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 19.6px;
      max-width: 324px;
    }
    @media (max-width: 640px) {
      max-width: 80%;
    }
    @media (max-width: 480px) {
      line-height: 21px;
    }
  }
  &__button {
    margin-top: 42px;
    background-color: $w-blue;
    @include transition;
    &:hover {
      background-color: #3970ec;
    }
    @media (max-width: 640px) {
      max-width: 80%;
    }
    @media (max-width: 480px) {
      margin-top: 32px;
    }
  }
  &__img {
    position: absolute;
    right: 0;
    top: 0;
    width: 708px;
    @media (max-width: 1400px) {
      top: 33px;
      width: 47%;
    }

    @media (max-width: 1200px) {
      // width: 402px;
      top: 87px;
    }
    @media (max-width: 744px) {
      // width: 340px;
    }
    @media (max-width: 640px) {
      max-width: 365px;
      width: 90%;
      top: 90px;
    }
    @media (max-width: 480px) {
      top: 96px;
    }
    @media (max-width: 375px) {
      top: 96px;
    }
  }
}

//ADVANTAGES
.advantages {
  position: relative;
  z-index: 2;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin-top: 40px;
    .advantages__review {
      display: none;
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
      margin-top: 32px;
      .advantages__review {
        display: block;
      }
    }
    @media (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media (max-width: 480px) {
      margin-top: 24px;
      grid-gap: 24px;
    }
  }
  &__top-items {
    @media (max-width: 1200px) {
      .advantages__review {
        display: none;
      }
    }
  }
  &__main-item {
    min-width: 660px;
    margin-right: 40px;
    background: linear-gradient(0deg, #074ce7, #074ce7),
      linear-gradient(
        90.32deg,
        rgba(0, 0, 0, 0) 0.28%,
        rgba(0, 0, 0, 0.2) 99.75%
      );
    padding: 32px;
    border-radius: 16px;
    @media (max-width: 1200px) {
      min-width: 0;
      width: 100%;
      margin-right: 0;
    }
    @media (max-width: 375px) {
      padding: 24px;
    }
    &__title {
      font-family: "Montserrat Alternates";
      font-weight: 500;
      font-size: 32px;
      line-height: 44.8px;
      max-width: 450px;
      color: #ffffff;
      @media (max-width: 1200px) {
        max-width: 100%;
      }
      @media (max-width: 960px) {
        font-size: 24px;
        line-height: 33.6px;
      }
      @media (max-width: 480px) {
        font-weight: 500;
      }
      span {
        font-weight: 600;
        @media (max-width: 480px) {
          font-weight: 500;
        }
      }
    }
    &__button {
      background-color: #fe9201;
      margin-top: 15px;
      @include transition;
      &:hover {
        background-color: #ffa328;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
      span {
        color: #232428;
        width: 153px;
        margin-right: 4px;
      }
      svg {
        width: 12px;
        height: 10.75px;
      }
    }
  }
  &__review {
    border: 1px solid #e9e9e9;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;

    &__img {
      border-radius: 50%;
      min-width: 52px;
      height: 52px;
      overflow: hidden;
      margin-right: 24px;
      @media (max-width: 1200px) {
        border-radius: 0;
        min-width: 100%;
        height: auto;
        overflow: hidden;
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 1200px) {
          width: auto;
          height: auto;
        }
      }
      .avatar {
        width: 52px;
        height: 52px;
        margin-right: 24px;
      }
    }
    &__content {
      &__name {
        color: #262626;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
      }
      &__mark {
        margin-top: 4px;
        &-item {
          margin-right: 2px;
          width: 12px;
          height: 12px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &__text {
        margin-top: 12px;
        color: #333333;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: 0.2px;
      }
      &__links {
        margin-top: 16px;
        &-item {
          margin-right: 32px;
          gap: 5px;
          &:hover {
            .chevron-right {
              margin-left: 8px;
            }
          }
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 19.6px;
            color: $dark-900;
          }
          &:last-child {
            margin-right: 0;
            @media (max-width: 1200px) {
              display: none;
            }
          }
          .play-03 {
            width: 14px;
            height: 22px;
          }
          .chevron-right {
            width: 24px;
            height: 24px;
            @include transition;
          }
        }
      }
    }
  }
  &__item {
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    padding: 32px 26px 32px 32px;
    @media (max-width: 1200px) {
      padding: 32px;
    }
    @media (max-width: 375px) {
      padding: 24px;
    }
    &__title {
      font-family: "Montserrat Alternates";
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: #002b79;
    }
    &__text {
      margin-top: 16px;
      color: $dark-900;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

//BRANDS

.brands {
  margin-top: 64px;
  &-container {
    &__item {
      margin-right: 43px;
      @media (max-width: 744px) {
        margin-right: 24px;
      }
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 640px) {
        flex: 1;
        margin-right: 23.13px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__text {
    margin: 19px auto 0;
    color: #b6b6b6;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}

//CATALOG-SELECT

.catalog-select {
  margin-top: 100.99px;
  @media (max-width: 960px) {
    margin-top: 47.99px;
  }
  @media (max-width: 480px) {
    margin-top: 29.19px;
  }

  .container {
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid #e9e9e9;
    padding: 32px;
    @media (max-width: 375px) {
      padding: 24px;
    }
  }
  &__title {
    color: $dark-900;
    font-weight: 500;
    font-size: 32px;
    line-height: 41.6px;
    @media (max-width: 960px) {
      font-size: 24px;
      line-height: 31.2px;
    }
    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 41.6px;
      font-weight: 600;
    }
  }
  &__nav {
    margin-top: 24px;
    display: inline-flex;
    border-radius: 4px;
    &__brand {
      margin-top: 8px;
      flex-wrap: wrap;
      display: flex;
      border: none;
      border-radius: 0;
      @media (max-width: 960px) {
        padding-right: 120px;
      }
      @media (max-width: 744px) {
        padding-right: 30px;
      }
      @media (max-width: 640px) {
        padding-right: 46px;
      }
      @media (max-width: 480px) {
        padding: 0;
      }
    }
    &-item {
      position: relative; // Добавляем позиционирование
      padding: 8px 16px;
      cursor: pointer;
      border: 1px solid #e9e9e9;
      margin-left: -1px;
      @include transition;
      color: $dark-900;
      font-size: 14px;
      line-height: 18.2px;
      @media (max-width: 375px) {
        padding: 6px 12px;
      }
      &:first-child {
        margin-left: 0;
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &__brand {
        margin-right: 8px;
        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid #e9e9e9;
        padding: 8px 16px;
        @include transition;
        color: $dark-900;
        font-size: 14px;
        line-height: 18.2px;

        &:last-child {
          margin-right: 0;
        }
        &:hover {
          border: 1px solid #cdddff;
          background-color: #f3f5fc;
          z-index: 1;
        }
        @media (max-width: 480px) {
          padding: 12px 20px;
        }
        @media (max-width: 375px) {
          padding: 8px 16px;
        }
      }

      &:hover {
        border: 1px solid #cdddff;
        background-color: #cdddff;
        z-index: 1;
      }

      &-active {
        background-color: #edf2fd;
        border: 1px solid #cdddff;
        position: relative;
        z-index: 2;
        &:hover {
          background-color: #edf2fd;
          border: 1px solid #cdddff;
        }
      }
    }
  }
  &__subtitle {
    margin-top: 24px;
    font-weight: 500px;
    font-size: 16px;
    line-height: 20.8px;
    color: $dark-900;
  }
  &__button {
    background-color: #074ce7;
    margin-top: 24px;
    @include transition;
    &:hover {
      background-color: #3970ec;
    }
    &.home-filter-btn {
      display: flex !important;
      width: fit-content;
      @media (max-width: 480px) {
        width: 100%;
      }
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

//RECOMENDATION

.recomendation {
  margin-top: 80px;
  @media (max-width: 960px) {
    margin-top: 48px;
  }
  &__title {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 32px;
    line-height: 41.6px;
    @media (max-width: 960px) {
      font-size: 24px;
      line-height: 31.2px;
    }
  }
  &-container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
    }
    @media (max-width: 960px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media (max-width: 480px) {
      grid-gap: 24px;
    }
    &__card {
      border: 1px solid #ececec;
      border-radius: 8px;
      background-color: #fff;
      padding: 15px;
      @include transition;
      &__cost--title {
        color: #8f8f8f;
        font-size: 12px;
        line-height: 14.52px;
        margin-top: 32px;
      }
      &:hover {
        .recomendation-container__card__name {
          color: #074ce7;
        }
        box-shadow: 0px 4px 25px 0px #3032371a;
      }
      .price--column {
        color: $dark-900;
        font-size: 12px;
        line-height: 14.52px;
        padding: 8px 12px;
        background-color: rgba(1, 254, 72, 0.1);
        margin-top: 12px;
      }
      &__img {
        width: 228px;
        height: 160px;
        overflow: hidden;
        border-radius: 5px;
        @media (max-width: 1200px) {
          width: 250px;
        }
        @media (max-width: 960px) {
          width: 292px;
        }
        @media (max-width: 745px) {
          width: 239.5px;
        }
        @media (max-width: 640px) {
          width: 100%;
          height: auto;
        }
        @media (max-width: 480px) {
          width: 310px;
          height: 160px;
        }
        @media (max-width: 375px) {
          width: 268px;
        }
        &-line {
          width: 260px;
          height: 180px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &__name {
        margin-top: 24px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19.36px;
        color: $dark-900;
        @include transition;
      }
      &__specs {
        margin-top: 5px;
        flex-wrap: wrap;
        p {
          color: #7b7882;
          font-size: 14px;
          line-height: 16.94px;
        }
        .dot {
          background-color: $dark-900;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      &__spec-marks {
        margin-top: 11px;
        flex-wrap: wrap;
        &__item {
          background: rgba(254, 146, 1, 0.1);
          border-radius: 2px;
          margin-right: 5px;
          margin-top: 5px;
          color: $dark-900;
          padding: 8px 12px;
          font-size: 12px;
          line-height: 14.52px;
        }
      }
      &__text {
        margin-top: 32px;
        color: #8f8f8f;
        font-size: 12px;
        line-height: 16.8px;
        @media (max-width: 960px) {
          margin-top: 24px;
        }
      }
      &__cost {
        margin-top: 12px;
        gap: 24px;
        flex-wrap: wrap;
        // justify-content: space-between;
        .main {
          color: $dark-900;
          font-weight: 600;
          font-size: 20px;
          line-height: 24.2px;
        }
        .secondary {
          color: #606066;
          font-family: "Montserrat Alternates";
          font-weight: 500;
          font-size: 15px;
          line-height: 18.29px;
        }
        .price--line {
          color: $dark-900;
          font-size: 12px;
          line-height: 14.52px;
          padding: 8px 12px;
          background-color: rgba(1, 254, 72, 0.1);
        }
      }
      &-fav {
        padding: 16px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin-left: auto;
        position: relative;
        z-index: 999;
        .fav-active {
          display: none;
        }
        svg {
          width: 24px;
          height: 24px;
        }
        &.active {
          .fav-active {
            display: block;
          }
          .fav_icon {
            display: none;
          }
          // background-color: #E7EEFD;
          border-color: #e7eefd;
          border-radius: 1px solid #e7eefd;
        }
        @include transition;
        &:hover {
          .fav-active {
            display: block;
          }
          .fav_icon {
            display: none;
          }
          background-color: #e7eefd;
          border-radius: 1px solid #e7eefd;
        }
      }
    }
  }
  &__button {
    background-color: #074ce7;
    @include transition;
    &:hover {
      background-color: #3970ec;
    }
    &-cont {
      margin: 32px auto 0;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

//ABOUT

.about {
  margin-top: 64px;
  @media (max-width: 960px) {
    margin-top: 80px;
  }
  @media (max-width: 480px) {
    margin-top: 68px;
  }
  &__title {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    @media (max-width: 960px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__container {
    margin-top: 32px;
    @media (max-width: 960px) {
      flex-direction: column;
    }
    &-content {
      flex: 1;
      margin-right: 42px;
      @media (max-width: 1200px) {
        max-width: 604px;
      }
      &__text-sec {
        padding-bottom: 24px;
        border-bottom: 1px solid #e9e9e9;
        @media (max-width: 640px) {
          max-width: 449px;
        }
      }
      &__text {
        font-family: "Montserrat Alternates";
        font-weight: 500;
        font-size: 48px;
        line-height: 57.6px;
        color: $dark-900;
        max-width: 825px;

        @media (max-width: 960px) {
          font-size: 32px;
          line-height: 38.4px;
        }

        span {
          color: #074ce7;
        }
      }
      &__undertext {
        color: $dark-900;
        font-size: 16px;
        line-height: 19.36px;
        margin-top: 16px;
        max-width: 493px;
        @media (max-width: 960px) {
          max-width: 323px;
        }
        @media (max-width: 480px) {
          font-size: 14px;
          line-height: 16.94px;
        }
      }
      &__advantages {
        margin-top: 24px;
        @media (max-width: 640px) {
          flex-direction: column;
          align-items: flex-start;
        }
        &__item {
          margin-right: 40px;
          @media (max-width: 640px) {
            margin-top: 40px;
            margin-right: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          @media (max-width: 640px) {
            &:first-child {
              margin-top: 0;
            }
          }

          &-title {
            font-family: "Montserrat Alternates";
            font-weight: 500;
            font-size: 48px;
            line-height: 58.51px;
            color: $dark-900;
            span {
              color: #074ce7;
            }
          }
          &-text {
            margin-top: 4px;
            font-size: 14px;
            line-height: 16.94px;
            color: $dark-900;
          }
        }
      }
    }
    &-photo {
      @media (max-width: 960px) {
        display: none;
      }
      @media (max-width: 480px) {
        display: block;
        margin-top: 42px;
        width: 100%;
      }
      &__img {
        width: 260px;
        height: 348px;
        border-radius: 16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media (max-width: 480px) {
          width: 100%;
          height: 348px;
        }
      }
      &__name {
        margin-top: 16px;
        color: $dark-900;
        font-weight: 500;
        font-size: 24px;
        line-height: 29.05px;
      }
      &__sub-name {
        margin-top: 4px;
        font-size: 14px;
        line-height: 16.94px;
        color: #999999;
      }
      &__button {
        margin-top: 12px;
        background-color: #074ce7;
        width: 100%;
        @include transition;
        &:hover {
          background-color: #3970ec;
        }
      }
    }
  }
}

//DOP-SERVICES

.dop-services {
  margin-top: 64px;
  @media (max-width: 960px) {
    margin-top: 80px;
  }
  @media (max-width: 480px) {
    margin-top: 37px;
  }
  &__title {
    max-width: 559px;
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 32px;
    line-height: 41.6px;
    @media (max-width: 960px) {
      font-size: 24px;
      line-height: 31.2px;
    }
  }
  &__container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 360px 760px;
    grid-template-rows: auto auto;
    grid-gap: 40px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
    }
    @media (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px;
    }
    &__item {
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      &:hover {
        .dop-services__container__item__text {
          .dop-services__container__item__text-link {
            svg {
              margin-left: 25px;
            }
          }
        }
      }
      img {
        width: 100%;
        height: 323px;
        object-fit: cover;
        @media (max-width: 744px) {
          height: 271px;
        }
        @media (max-width: 480px) {
          height: 323px;
        }
      }
      &__text {
        position: absolute;
        bottom: 32px;
        left: 24px;
        &-title {
          font-family: "Montserrat Alternates";
          font-weight: 700;
          font-size: 24px;
          line-height: 28.8px;
          color: #fff;
          max-width: 283px;
        }
        &-link {
          font-weight: 500;
          font-size: 14px;
          line-height: 16.94px;
          color: #fff;
          margin-top: 16px;

          svg {
            margin-left: 17px;
            @include transition;
            width: 12px;
            height: 10.75px;
          }
        }
      }
      &-large {
        grid-column: 1 / -1;
        background: linear-gradient(0deg, #074ce7, #074ce7),
          linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
        border-radius: 16px;
        margin-top: 24px;
        padding: 64px;
        overflow: hidden;
        @media (max-width: 1200px) {
          padding: 40px;
          margin-top: 0;
        }
        @media (max-width: 960px) {
          padding-bottom: 47px;
        }
        @media (max-width: 480px) {
          display: flex;
          flex-direction: column;
          padding: 32px;
        }
        @media (max-width: 375px) {
          padding: 24px;
        }
        .dop-services__container__item__text {
          position: inherit;
          max-width: 100%;
          bottom: auto;
          left: auto;
          @media (max-width: 480px) {
            order: 1;
          }
          &-title {
            font-family: "Montserrat Alternates";
            font-weight: 500;
            font-size: 32px;
            line-height: 38.4px;
            color: #fff;
            max-width: 626px;
            @media (max-width: 960px) {
              max-width: 359px;
              font-size: 24px;
              line-height: 28.8px;
            }
            @media (max-width: 744px) {
              max-width: 263px;
            }
            @media (max-width: 375px) {
              font-size: 22px;
            }
          }
          &-subtitle {
            font-size: 14px;
            line-height: 19.6px;
            color: #fff;
            margin-top: 24px;
            max-width: 423px;
            @media (max-width: 960px) {
              max-width: 300px;
            }
            @media (max-width: 744px) {
              max-width: 250px;
            }
            @media (max-width: 480px) {
              max-width: 100%;
            }
          }
        }
      }
      &__button {
        background-color: #fe9201;
        width: 359px;
        color: #232428;
        margin-top: 24px;
        height: 52px;
        @include transition;
        &:hover {
          background-color: #ffa328;
        }
        @media (max-width: 960px) {
          max-width: 281px;
        }
        @media (max-width: 744px) {
          max-width: 239px;
        }
        @media (max-width: 480px) {
          order: 2;
          max-width: 100%;
        }
      }
      &__img-cont {
        position: absolute;
        left: 782px;
        top: 53px;
        background-color: #fff;
        border-radius: 70px 0 0 70px;
        padding: 25.99px 0 26.04px 46.02px;
        .carmobile {
          display: none;
          width: 233.4px;
          height: 98.4px;
          @media (max-width: 375px) {
            width: 212.4px;
          }
        }

        @media (max-width: 1200px) {
          left: 590px;
          top: 22px;
        }
        @media (max-width: 960px) {
          left: 415px;
          top: 40px;
        }
        @media (max-width: 744px) {
          left: 319px;
        }
        @media (max-width: 480px) {
          position: inherit;
          left: auto;
          top: auto;
          order: 3;
          margin-top: 48px;
          padding: 12.8px 22.8px;
          border-radius: 36px;
          .carmobile {
            display: block;
          }
          .carright {
            display: none;
          }
        }
        @media (max-width: 375px) {
          padding: 12.8px 22.8px;
        }
        svg {
          width: 470.99px;
          height: 199.97px;
        }
      }
    }
  }
}

//JOURNAL

.journal {
  margin-top: 64px;
  @media (max-width: 960px) {
    margin-top: 48px;
  }
  &__title {
    color: $dark-900;
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    @media (max-width: 960px) {
      font-size: 24px;
    }
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 40px;
    margin-top: 32px;
    @media (max-width: 960px) {
      grid-gap: 32px;
    }
    @media (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px;
    }
    @media (max-width: 640px) {
      margin-top: 32px;
    }
    &__item {
      &-big {
        grid-column: span 3;
        @media (max-width: 1200px) {
          .journal__container__item__img {
            height: 240px;
          }
        }
        @media (max-width: 960px) {
          .journal__container__item__img {
            height: 190px;
          }
        }
        @media (max-width: 744px) {
          .journal__container__item__img {
            height: 159px;
          }
        }
        @media (max-width: 640px) {
          grid-column: span 1;
          .journal__container__item__img {
            height: 340px;
          }
        }
        @media (max-width: 480px) {
          .journal__container__item__img {
            height: 320px;
          }
        }
      }
      &-small {
        grid-column: span 2;
        .journal__container__item__title {
          font-size: 20px;
          line-height: 28px;
        }
        @media (max-width: 1200px) {
          grid-column: span 3;
          .journal__container__item__img {
            height: 240px;
          }
        }
        @media (max-width: 960px) {
          .journal__container__item__img {
            height: 190px;
          }
        }
        @media (max-width: 744px) {
          .journal__container__item__img {
            height: 159px;
          }
        }
        @media (max-width: 640px) {
          grid-column: span 1;
          .journal__container__item__img {
            height: 340px;
          }
        }
        @media (max-width: 480px) {
          .journal__container__item__img {
            height: 320px;
          }
        }
      }
      &__img {
        width: 100%;
        height: 320px;
        border-radius: 16px;
        overflow: hidden;
        display: block;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__marks {
        margin-top: 16px;
        &-mark {
          color: $dark-900;
          font-size: 14px;
          line-height: 18.2px;
          margin-right: 8px;
          padding: 4px 12px;
          background-color: rgba(7, 76, 231, 0.1);
          border-radius: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &__title {
        margin-top: 8px;
        font-size: 24px;
        line-height: 33.6px;
        color: $dark-900;
        font-weight: 400;
        display: block;
        @media (max-width: 960px) {
          font-size: 20px;
          line-height: 28px;
        }
      }
      &__link {
        margin-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.94px;
        border-bottom: 1px solid #b3b3b3;
        display: inline-flex;
        padding-bottom: 4px;
        &:hover {
          svg {
            margin-left: 25px;
          }
        }
        svg {
          margin-left: 17px;
          width: 12px;
          height: 10.75px;
          @include transition;
        }
      }
      &__undertext {
        margin-top: 8px;
        color: #7b7882;
        font-size: 14px;
        line-height: 16.8px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 34px;
      }
    }
  }
  &__button {
    background-color: #074ce7;
    margin-top: 32px;
    @include transition;
    &:hover {
      background-color: #3970ec;
    }
    @media (max-width: 480px) {
      width: 343px;
    }
  }
}

//REVIEWS

.reviews {
  margin-top: 80px;
  overflow: hidden;
  &__top-items {
    @media (max-width: 960px) {
      flex-direction: column;
    }
    &__title {
      font-family: "Montserrat Alternates";
      font-weight: 500;
      color: #333;
      font-size: 32px;
      line-height: 38.4px;
      max-width: 560px;
      @media (max-width: 960px) {
        font-size: 24px;
        line-height: 28.8px;
        max-width: 400px;
      }
    }

    &__buttons {
      @media (max-width: 960px) {
        margin-top: 24px;
      }
      &-bnt {
        cursor: pointer;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  &__slider-wrapper {
    margin-top: 32px;
    position: relative;
    overflow: visible;
    padding-right: 26%;
  }

  &__swiper {
    overflow: visible;
    width: calc(100% + 50%);
  }

  &__items {
    display: flex;

    .advantages__review {
      width: 460px;
      margin-right: 32px;
      @media (max-width: 960px) {
        width: 324px;
      }
      @media (max-width: 480px) {
        width: 343px;
      }
      @media (max-width: 375px) {
        width: 300px;
      }
      &--desctop {
        @media (max-width: 1200px) {
          display: none;
        }
      }
      &__mobile {
        display: none;
        @media (max-width: 1200px) {
          display: block;
        }
      }
    }

    .swiper-slide {
      align-items: flex-start;
    }
  }
}

//JOURNAL-PAGE
.journal-page {
  margin-top: 29px;
  @media (max-width: 480px) {
    .journal__container__item__img {
      height: 195px;
    }
  }
  .journal {
    &__subtitle {
      margin-top: 8px;
      color: $dark-900;
      font-size: 14px;
      line-height: 21px;
      max-width: 360px;
      @media (max-width: 960px) {
        margin-top: 24px;
      }
      @media (max-width: 480px) {
        margin-top: 12px;
      }
    }
    &-sec {
      margin-top: 48px;
      &-main {
        margin-top: 82px;
        @media (max-width: 960px) {
          margin-top: 40px;
        }
        @media (max-width: 480px) {
          margin-top: 60px;
        }
      }
      &__title {
        color: $dark-900;
        font-family: "Montserrat Alternates";
        font-size: 32px;
        line-height: 32px;
        font-weight: 500;
        img {
          margin-left: 12px;
        }
      }
    }
  }
}

.journal-same {
  margin-top: 149px;
  @media (max-width: 960px) {
    margin-top: 64px;
  }
}

//ABOUT

.advantages {
  .dop-services__container__item__img-cont {
    .carright {
      @media (max-width: 1200px) {
        display: none;
      }
    }
    .carmobile {
      @media (max-width: 1200px) {
        display: block;
      }
    }
  }
  .dop-services__container__item-large {
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      .dop-services__container__item__img-cont {
        order: 3;
        position: inherit;
        padding: 25.79px 50.33px;
        border-radius: 36px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 48px;
        svg {
          width: 515.32px;
          height: 198.38px;
          margin: 0 auto;
        }
      }
      .dop-services__container__item__text {
        order: 1;
        &-title {
          max-width: 100%;
        }
        &-subtitle {
          max-width: 80%;
        }
      }
      .dop-services__container__item__button {
        order: 2;
        width: 80%;
      }
    }
    @media (max-width: 960px) {
      padding: 32px;
      .dop-services__container__item__text {
        &-subtitle {
          max-width: 100%;
        }
      }
      .dop-services__container__item__button {
        max-width: 100%;
        width: 100%;
      }
    }
    @media (max-width: 640px) {
      .dop-services__container__item__img-cont {
        svg {
          width: 233.4px;
          height: 98.4px;
        }
      }
    }
    @media (max-width: 480px) {
      .dop-services__container__item__img-cont {
        padding: 12.79px 22.79px;
        svg {
          width: 233.4px;
          height: 98.4px;
        }
      }
    }
    @media (max-width: 375px) {
      .dop-services__container__item__img-cont {
        padding: 12.79px 22.79px;
        svg {
          width: 212.4px;
        }
      }
    }
  }

  &-about {
    margin-top: 80px;
    .advantages__title {
      color: $dark-900;
      font-weight: 500;
      font-family: "Montserrat Alternates";
      font-size: 32px;
      line-height: 38.4px;
    }
    .grid-container {
      margin-top: 32px;
    }
    .dop-services__container__item-large {
      margin-top: 40px;
    }
  }
}

.director {
  margin-top: 80px;
  @media (max-width: 640px) {
    margin-top: 48px;
    .container {
      flex-direction: column;
    }
  }

  &__content {
    &__title {
      max-width: 858px;
      font-weight: 200;
      font-style: italic;
      font-size: 48px;
      line-height: 57.6px;
      color: $dark-900;
      @media (max-width: 1200px) {
        margin-right: 43px;
        max-width: 100%;
      }
      @media (max-width: 960px) {
        font-size: 32px;
        line-height: 38.4px;
      }
      @media (max-width: 640px) {
        margin-right: 0;
      }
    }
    &__name {
      margin-top: 24px;
      font-weight: 500;
      color: $dark-900;
      font-size: 24px;
      line-height: 29.05px;
      @media (max-width: 960px) {
        display: none;
      }
      @media (max-width: 480px) {
        margin-right: 16px;
        line-height: 29.05px;
      }
    }
    &__post {
      margin-top: 4px;
      color: #999999;
      font-size: 14px;
      line-height: 16.94px;
      @media (max-width: 960px) {
        display: none;
      }
    }
  }
  &__photo {
    width: 260px;
    height: 348px;
    overflow: hidden;
    border-radius: 16px;
    @media (max-width: 960px) {
      height: 173px;
    }
    @media (max-width: 640px) {
      width: 343px;
      height: 343px;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
    &-wrapper {
      .director__content__name {
        display: none;
      }
      .director__content__post {
        display: none;
      }
      @media (max-width: 960px) {
        .director__content__name {
          display: block;
        }
        .director__content__post {
          display: block;
        }
      }
      @media (max-width: 640px) {
        margin-top: 42px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

//CONTACTS

.contacts {
  margin-top: 80px;

  &__content {
    &-sec {
      background-color: #f6f6f6;
      border-radius: 16px;
      padding: 40px 50px 40px 32px;
    }
    margin-right: 40px;
    width: 360px;
    @media (max-width: 960px) {
      width: 100%;
    }
    &__main-title {
      color: $dark-900;
      font-family: "Montserrat Alternates";
      font-weight: 600;
      font-size: 24px;
      line-height: 29.26px;
    }
    &-info {
      margin-top: 40px;
    }
    &__title {
      color: #606066;
      font-size: 14px;
      line-height: 16.94px;
      margin-top: 24px;
    }
    &__text-sec {
      margin-top: 8px;
      &__item {
        color: $dark-900;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.94px;
        display: block;
      }
    }
    &__text-last {
      margin-top: 24px;
      font-size: 14px;
      color: $dark-900;
      line-height: 16.94px;
      a {
        color: #074ce7;
      }
    }
  }
  &__button {
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    color: $dark-900;
    width: 100%;
    margin-top: 48px;
    @include transition;
    &:hover {
      background-color: #f8f8f8;
    }
  }
}

//CONTACTS
.contacts-page {
  display: flex;
  flex-direction: column;
  margin-top: 67px;
  .container-map__title {
    display: none;
    color: #1c1c24;
    font-size: 24px;
    line-height: 31.2px;
    font-weight: 500;
    font-family: "Montserrat Alternates";
  }
  .contacts__map-frame__mobile {
    display: none;
  }
  @media (max-width: 640px) {
    .contacts__map-frame__desktop {
      display: none;
    }
    .contacts__map-frame__mobile {
      display: block;
    }
  }
  @media (max-width: 1200px) {
    margin-top: 24px;
    .container-content {
      order: 2;
      margin-top: 40px;
      flex-direction: column;
    }
    .container-map {
      order: 1;
    }
    .container-map__title {
      display: block;
    }
    .contacts__info-wrapper {
      display: flex;
      @media (max-width: 640px) {
        flex-direction: column;
      }
    }
  }
}
.contacts {
  &-about {
    .contacts__map-about {
      flex: 1;
      height: 480px;
      border-radius: 20px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 960px) {
        order: 1;
        width: 100%;
      }
    }
    .contacts__content {
      @media (max-width: 960px) {
        order: 2;
        margin-top: 40px;
        margin-right: 0;
      }
    }
    .container {
      @media (max-width: 960px) {
        flex-direction: column;
      }
    }
    .contacts__content-sec {
      @media (max-width: 960px) {
        display: flex;

        .contacts__content-sec-wrapper {
          margin-right: 40px;
        }
      }
      .contacts__button {
        display: none;
        @media (max-width: 960px) {
          display: inline-flex;
          max-height: 49px;
          color: #fff;
          margin-top: 0;
          max-width: 287px;
          &:hover {
            background-color: #3970ec;
          }
        }
        @media (max-width: 640px) {
          display: none;
        }
      }
    }
    .white-button {
      @media (max-width: 960px) {
        display: none;
      }
      @media (max-width: 640px) {
        display: inline-flex;
        margin-top: 16px;
      }
    }
  }
  .container {
    align-items: stretch;
    height: 100%;
  }

  &__info {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (max-width: 1200px) {
      max-width: 100%;
    }
    &__title {
      color: $dark-900;
      font-family: "Montserrat Alternates";
      font-weight: 500;
      font-size: 48px;
      line-height: 57.6px;
      @media (max-width: 1200px) {
        font-size: 32px;
        line-height: 38.4px;
        margin-right: 32px;
        max-width: 442px;
      }
      @media (max-width: 640px) {
        margin-right: 0;
      }
      @media (max-width: 375px) {
        font-size: 28px;
      }
    }
    &__schedule {
      margin-top: 64px;
      @media (max-width: 1200px) {
        margin-top: 0;
        margin-left: auto;
        flex-direction: column;
      }
      @media (max-width: 640px) {
        margin-left: 0;
        margin-top: 40px;
      }
      &__title {
        color: #77777c;
        font-size: 14px;
        line-height: 16.94px;
        @media (max-width: 1200px) {
          line-height: 18.2px;
        }
      }
      &-time__text {
        color: $dark-900;
        font-weight: 600;
        font-size: 14px;
        line-height: 18.2px;
        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
      &-contacts {
        @media (max-width: 1200px) {
          margin-top: 32px;
        }
        @media (max-width: 640px) {
          margin-top: 24px;
        }
      }
      &-contacts__links {
        margin-top: 8px;

        &-link {
          color: $dark-900;
          font-weight: 500;
          font-size: 14px;
          line-height: 16.94px;
          display: block;
          @media (max-width: 745px) {
            font-size: 12px;
          }
          @media (max-width: 640px) {
            font-size: 14px;
          }
        }
      }
    }
    &__last-line {
      width: 560px;
      padding: 24px 0;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      @media (max-width: 1200px) {
        width: 100%;
        margin-top: 40px;
      }
      p {
        max-width: 278px;
        color: $dark-900;
        font-size: 14px;
        line-height: 16.94px;
        a {
          color: #074ce7;
        }
      }
    }
  }
  &__form {
    max-width: 460px;
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    padding: 40px 55px;
    @media (max-width: 1200px) {
      max-width: 100%;
      margin-top: 43px;
    }
    @media (max-width: 640px) {
      padding: 24px;
    }
    &__title {
      font-family: "Montserrat Alternates";
      font-weight: 600;
      font-size: 24px;
      line-height: 29.26px;
      color: $dark-900;
    }
    &__form {
      margin-top: 40px;
      @media (max-width: 1200px) {
        max-width: 567px;
      }
      &-group {
        margin-top: 24px;
        p {
          color: #33333a;
          font-size: 14px;
          line-height: 21px;
          span {
            color: #f84b40;
          }
        }
        input {
          padding: 16px 12px;
          margin-top: 2px;
          background-color: #f8fafc;
          font-size: 14px;
          line-height: 21px;
          color: $dark-900;
          border-radius: 4px;
          width: 100%;
          &::placeholder {
            color: #a4a4a7;
          }
        }
      }
      &-button {
        width: 100%;
        margin-top: 24px;
      }
    }
    &__notice {
      margin-top: 24px;
      color: #8d8d91;
      font-size: 12px;
      line-height: 16.8px;
      @media (max-width: 1200px) {
        max-width: 567px;
      }
      a {
        color: #074ce7;
        text-decoration: underline;
        @include transition;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &__map-frame {
    margin-top: 60px;
    width: 100%;
    height: 448px;
    border-radius: 20px;
    overflow: hidden;
    .contacts__map {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-about {
      margin-top: 0;
    }
    @media (max-width: 1200px) {
      margin-top: 33px;
      height: 346px;
    }
    @media (max-width: 960px) {
      height: 263px;
    }
    @media (max-width: 640px) {
      margin-top: 40px;
      height: 448px;
    }
  }
}

//PURCHASE

.purchase {
  margin-top: 29px;
  @media (max-width: 960px) {
    margin-top: 24px;
  }
  &__title {
    color: $dark-900;
    font-weight: 500;
    font-size: 32px;
    line-height: 41.6px;
    font-family: "Montserrat Alternates";
  }
  &__stage {
    margin-top: 24px;
    @media (max-width: 960px) {
      margin-top: 12px;
    }
    @media (max-width: 640px) {
      margin-top: 24px;
    }
    &:nth-child(2) {
      margin-top: 40px;
      @media (max-width: 960px) {
        margin-top: 32px;
      }
      @media (max-width: 640px) {
        margin-top: 24px;
      }
    }
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    &__content {
      flex-direction: column;
      padding: 32px 24px 32px 24px;
      height: 320px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1a3972 100%);

      @media (max-width: 960px) {
        height: 260px;
      }
      @media (max-width: 744px) {
        height: 220px;
      }
      @media (max-width: 640px) {
        height: 320px;
      }
      &__number {
        font-family: "Montserrat Alternates";
        font-size: 64px;
        line-height: 76.8px;
        font-weight: 700;
        color: #fff;
      }
      &__text {
        max-width: 400px;
        @media (max-width: 960px) {
          max-width: 100%;
        }
        &-title {
          font-family: "Montserrat Alternates";
          font-weight: 700;
          font-size: 24px;
          line-height: 28.8px;
          color: #fff;
        }
        &-text {
          font-size: 14px;
          line-height: 19.6px;
          font-weight: 500;
          margin-top: 16px;
          color: #fff;
        }
      }
      img {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

//PRODUCT CARD

.product-card {
  overflow: hidden;
  margin-top: 40px;
  .row {
    margin: 0;
  }
  .col-8 {
    padding: 0;
  }
  .col-4 {
    padding: 0;
  }
  &__title {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 32px;
    line-height: 41.6px;
    color: $dark-900;
  }
  &__content {
    margin-top: 23px;
    // display: grid;
    // gap: 40px;
    // grid-template-columns: 760px 360px;
    &__slider {
      max-width: 760px;

      @media (max-width: 640px) {
        overflow: visible;
        position: relative;
      }
      @media (max-width: 1200px) {
        max-width: 582px;
      }
      @media (max-width: 960px) {
        max-width: 443px;
      }
      @media (max-width: 744px) {
        max-width: 100%;
        width: 100%;
      }
      .swiper-container {
        @media (max-width: 640px) {
          overflow: visible;
          width: calc(100% + 50%);
          margin-left: 0;
          padding-right: 40%;
        }
      }
      &__main-img {
        width: 760px;
        height: 448px;
        border-radius: 8px;
        overflow: hidden;
        @media (max-width: 1200px) {
          width: 582px;
          height: 492px;
        }
        @media (max-width: 960px) {
          width: 443px;
          height: 375px;
          @media (max-width: 744px) {
            width: 100%;
          }
        }
        @media (max-width: 640px) {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include transition;
          &:hover {
            scale: 1.02;
          }
        }
      }
      &__imgs {
        margin-top: 12px;
        overflow: hidden;
        .swiper-slide:nth-child(n + 6) {
          display: none;
        }
        @media (max-width: 960px) {
          .swiper-slide:nth-child(4) {
            display: none;
          }
        }
        &__img {
          width: 146px;
          height: 112px;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 8px;
          border-radius: 8px;
          &:last-child {
            margin-right: 0;
            position: relative;
          }
          @media (max-width: 960px) {
            width: 106.9px;
            height: 82.01px;
          }
          @media (max-width: 640px) {
            width: 314px;
            height: 241px;
          }
          .product-card__stub {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.6);
          }
          .img-text {
            font-weight: 700;
            color: #fff;
            font-size: 14px;
            line-height: 16.94px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &__price-sec {
      background-color: #fff;
      max-width: 360px;
      @media (max-width: 744px) {
        max-width: 100%;
        width: 100%;
        margin-top: 24px;
      }
      &__top-line {
        .shield-check {
          width: 32px;
          height: 32px;
        }
        .auction-line {
          background-color: #e6edfd;
          padding: 8px 12px;
          .hourglass {
            width: 16px;
            height: 16px;
          }
          div {
            margin-top: 4px;
          }
          span {
            color: $dark-900;
            font-weight: 600;
            font-size: 16px;
            line-height: 19.36px;
          }
        }
        &__info-line {
          padding: 12px 24px;
          border-radius: 8px;
          background-color: #e6ffed;
          width: 100%;
          margin-right: 8px;
          @media (max-width: 960px) {
            padding: 10.74px 14px 10.74px 12px;
            margin-right: 7px;
          }
          @media (max-width: 480px) {
            padding: 12px 12px 12px 24px;
          }
          @media (max-width: 360px) {
            padding: 12px;
          }
          p {
            color: $dark-900;
            font-size: 12px;
            line-height: 16.8px;
            @media (max-width: 960px) {
              font-size: 10.74px;
              line-height: 15.03px;
            }
            @media (max-width: 480px) {
              font-size: 12px;
              line-height: 16.8px;
            }
          }
          svg {
            margin-right: 8px;
            width: 32px;
            height: 32px;
            @media (max-width: 960px) {
              min-width: 28px;
              height: 28px;
            }
          }
        }
        &__fav-btn {
          padding: 16px;
          border: 1px solid #e9e9e9;
          border-radius: 5px;
          margin-left: auto;
          position: relative;
          z-index: 999;
          @media (max-width: 960px) {
            padding: 14.32px;
          }
          .fav-active {
            display: none;
          }
          svg {
            width: 24px;
            height: 24px;
            @media (max-width: 960px) {
              width: 21.47px;
              height: 21.47px;
            }
          }
          &.active {
            .fav-active {
              display: block;
            }
            .fav_icon {
              display: none;
            }
            // background-color: #E7EEFD;
            border: 1px solid #e7eefd;
          }
          @include transition;
          &:hover {
            .fav-active {
              display: block;
            }
            .fav_icon {
              display: none;
            }
            background-color: #e7eefd;
            border-radius: 1px solid #e7eefd;
          }
        }
      }
      &__main-info {
        margin-top: 24px;
        &__title {
          color: $dark-900;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          @media (max-width: 960px) {
            font-size: 17.9px;
            line-height: 21.47px;
          }
          @media (max-width: 480px) {
            font-size: 20px;
            line-height: 24px;
          }
        }

        &__line {
          padding: 16px 0 12px 0;
          border-bottom: 1px solid #ececec;
          gap: 8px;
          @media (max-width: 960px) {
            flex-direction: column;
            padding: 14.32px 0 9.68px 0;
          }
          @media (max-width: 744px) {
            flex-direction: row;
            padding: 16px 0 12px;
          }
          &__var {
            color: #454545;
            font-size: 14px;
            line-height: 16.94px;
            @media (max-width: 960px) {
              font-size: 12.53px;
              line-height: 15.16px;
            }
          }
          &__value {
            color: #000000;
            font-size: 14px;
            line-height: 16.94px;
            @media (max-width: 960px) {
              margin-top: 8px;
              font-size: 12.53px;
              line-height: 15.16px;
            }
            @media (max-width: 744px) {
              margin-top: 0;
            }
          }
        }
        &__price {
          margin-top: 16px;
          padding-bottom: 12px;
          @media (max-width: 960px) {
            margin-top: 14.32px;
            padding-bottom: 10.74px;
          }
          &__text {
            color: #454545;
            font-size: 14px;
            line-height: 16.94px;
          }
          &__value {
            margin-top: 12px;
            @media (max-width: 960px) {
              margin-top: 10.74px;
            }
            &__main {
              color: $dark-900;
              font-weight: 600;
              font-size: 24px;
              line-height: 29.05px;
              @media (max-width: 960px) {
                font-size: 21.74px;
                line-height: 25.99px;
              }
            }
            &__secondary {
              color: #000000;
              font-size: 14px;
              line-height: 16.94px;
              @media (max-width: 960px) {
                font-size: 12.53px;
                line-height: 15.16px;
              }
            }
          }
        }
      }
      &__buttons {
        margin-top: 16px;
        &__button {
          margin-top: 12px;
          width: 100%;
          @media (max-width: 960px) {
            padding: 14.32px;
            font-size: 12.53px;
            line-height: 15.16px;
          }
          &:first-child {
            margin-top: 0;
          }
        }
        .button-blue {
          background-color: #074ce7;
          @include transition;
          &:hover {
            background-color: #3970ec;
          }
        }

        .button-white {
          background-color: #fff;
          border: 1px solid #e9e9e9;
          color: $dark-900;
          @include transition;
          &:hover {
            background-color: #f8f8f8;
          }
        }
        &__info {
          background-color: #ececec;
          padding: 12px 26px 12px 20px;
          margin-top: 12px;
          border-radius: 4px;
          p {
            color: #77777c;
            font-size: 14px;
            line-height: 19.6px;
            margin-left: 8px;
          }
          .alert-triangle {
            max-width: 18px;
            max-height: 16px;
            margin-top: 8px;
          }
        }
      }
    }
    &__info {
      margin-top: 40px;
      padding: 32px;
      border-radius: 16px;
      border: 1px solid #e9e9e9;

      &__mobile {
        display: none;
        @media (max-width: 744px) {
          display: block;
          margin-top: 32px;
        }
        @media (max-width: 375px) {
          padding: 18px;
        }
      }
      &__desktop {
        @media (max-width: 744px) {
          display: none;
        }
      }
      .nav-tabs {
        .nav-link {
          padding: 8px 15px;
          border: 1px solid #e9e9e9;
          color: $dark-900;
          font-size: 14px;
          line-height: 18.2px;
          &:last-child {
            margin-left: -1px;
          }
          @media (max-width: 375px) {
            padding: 8px 12px;
          }
        }

        .active {
          background-color: #edf2fd;
          border: 1px solid #cdddff;
        }
      }
      .tab-content {
        margin-top: 24px;
        .product-card__content__info__line__title {
          color: $dark-900;
          font-weight: 500;
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 24px;
        }
        .product-card__content__info__line {
          @media (max-width: 480px) {
            flex-direction: column;
          }
        }
        .product-card__content__info__line-column {
          margin-right: 40px;
          @media (max-width: 480px) {
            margin-right: 0;
            margin-top: 40px;
            &:nth-child(1) {
              margin-top: 0;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          &__el {
            margin-top: 12px;
            &:first-child {
              margin-top: 0;
            }
            &-titel {
              color: #8f8f8f;
              font-size: 12px;
              line-height: 15.6px;
            }
            &-text {
              color: $dark-900;
              font-weight: 500;
              font-size: 14px;
              line-height: 20.8px;
              margin-top: 4px;
              @media (max-width: 960px) {
                font-size: 16px;
              }
              @media (max-width: 640px) {
                font-size: 14px;
              }
              @media (max-width: 480px) {
                font-size: 16px;
              }
            }
          }
        }
        .product-card__content__info__line-complect {
          &-title {
            color: $dark-900;
            font-weight: 600;
            font-size: 16px;
            line-height: 20.8px;
            margin-top: 24px;
            span {
              margin-left: 8px;
              font-weight: 500;
              font-size: 12px;
              line-height: 15.6px;
              color: #8f8f8f;
            }
          }
          &__grid {
            max-width: 530px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 0 40px;
            margin-top: 16px;
            padding-bottom: 24px;
            border-bottom: 1px solid #ececec;
            &:last-child {
              border: none;
              padding: 0;
            }
            @media (max-width: 480px) {
              grid-template-columns: repeat(1, 1fr);
            }
            &-item {
              .dot {
                width: 4px;
                height: 4px;
                background-color: $dark-900;
                border-radius: 50%;
                margin-right: 8px;
              }
              p {
                color: $dark-900;
                font-size: 14px;
                line-height: 25.2px;
              }
            }
          }
        }
      }
    }
  }
}

.same-cars {
  margin-top: 64px;
  &__title {
    font-family: "Montserrat Alternates";
    font-size: 32px;
    line-height: 41.6px;
    color: $dark-900;
    span {
      margin-left: 8px;
      color: #8f8f8f;
      font-size: 12px;
      line-height: 15.6px;
      font-weight: 500;
    }
  }
}

//ARTiCLE

.article {
  .container {
    max-width: 760px;
    position: relative;
    @media (max-width: 960px) {
      max-width: 680px;
    }
    @media (max-width: 744px) {
      max-width: 575px;
    }
    @media (max-width: 640px) {
      max-width: 449px;
    }
    @media (max-width: 480px) {
      max-width: 343px;
    }
    @media (max-width: 375px) {
      max-width: 300px;
    }
  }

  h1 {
    margin-top: 40px;
    color: $dark-900;
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 48px;
    line-height: 57.6px;
    max-width: 760px;
    @media (max-width: 960px) {
      margin-top: 64px;
      padding: 0 84px;
      font-size: 32px;
      line-height: 38.4px;
    }
    @media (max-width: 640px) {
      padding: 0;
    }
    @media (max-width: 480px) {
      margin-top: 24px;
      font-size: 24px;
      line-height: 31.2px;
    }
  }
  h2 {
    margin-top: 16px;
    color: $dark-900;
    font-size: 24px;
    line-height: 36px;
    max-width: 760px;
    font-weight: 400;
    @media (max-width: 960px) {
      padding: 0 84px;
      font-size: 20px;
      line-height: 30px;
      max-width: 100%;
      margin-top: 24px;
    }
    @media (max-width: 640px) {
      padding: 0;
    }
  }
  img {
    margin-top: 64px;
    max-width: 960px;
    height: 100%;
    margin-left: -100px;
    @media (max-width: 960px) {
      max-width: 100%;
      margin-left: 0;
    }
  }

  h3 {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
    color: $dark-900;
    margin-top: 40px;
    max-width: 560px;
    &:nth-child(1) {
      @media (max-width: 960px) {
        margin-top: 64px;
      }
    }
    @media (max-width: 960px) {
      margin-top: 19.87px;
      padding: 0 60px;
      max-width: 100%;
    }
    @media (max-width: 640px) {
      padding: 0;
    }
    @media (max-width: 480px) {
      margin-top: 40px;
    }
  }
  p {
    margin-top: 16px;
    color: $dark-900;
    font-size: 18px;
    line-height: 27px;
    max-width: 560px;
    @media (max-width: 960px) {
      padding: 0 60px;
      max-width: 100%;
    }
    @media (max-width: 640px) {
      padding: 0;
    }
  }

  &__main-content__dop-info {
    max-width: 360px;
    background-color: #fff5e6;
    border-radius: 16px;
    padding: 32px;
    position: absolute;
    top: 1737px;
    right: -200px;
    @media (max-width: 1200px) {
      max-width: 240px;
      right: -75px;
    }
    &--desctop {
      @media (max-width: 960px) {
        display: none;
      }
    }
    &__mobile {
      display: none;
      @media (max-width: 960px) {
        display: block;
        position: static;
        max-width: 100%;
        margin: 0 60px;
        margin-top: 19.88px;
      }
      @media (max-width: 640px) {
        margin: 16px 0 0;
      }
      @media (max-width: 375px) {
        padding: 24px;
      }
    }
    &__text {
      color: $dark-900;
      font-size: 16px;
      line-height: 24px;
    }
    &__link {
      color: #074ce7;
      font-weight: 500;
      font-size: 14px;
      line-height: 16.94px;
      margin-top: 16px;
      &:hover {
        svg {
          margin-left: 24px;
        }
      }
      svg {
        margin-left: 16px;
        width: 12px;
        height: 10.75px;
        @include transition;
      }
    }
  }

  &__top-content {
    max-width: 760px;
    margin: 54px auto 0;
    &__top-line {
      &__marks {
        &-mark {
          display: block;
          padding: 4px 12px;
          background-color: rgba(7, 76, 231, 0.1);
          color: $dark-900;
          font-size: 14px;
          line-height: 18.2px;
          border-radius: 8px;
        }
        &-date {
          margin-top: 8px;
          color: $dark-900;
          font-size: 14px;
          line-height: 21px;
          display: block;
        }
      }
      svg {
        width: 25.83px;
        height: 30.44px;
      }
    }
  }
}

//CATALOG
.catalog-page {
  .catalog-select__nav {
    margin-top: 0;
    @media (max-width: 1200px) {
      width: 100%;
      .catalog-select__nav-item {
        flex-grow: 1;
        padding: 11px 0;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
  @media (max-width: 1200px) {
    .col-9 {
      width: 100%;
    }
    .col-3 {
      display: none;
    }
  }
}
.catalog {
  margin-top: 29px;
  .row {
    margin-top: 32px;
  }
  &__title {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 32px;
    line-height: 41.6px;
    color: $dark-900;
  }
  &-select {
    &-page {
      margin-top: 0;
      padding: 24px 16px;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      @media (max-width: 960px) {
        width: 716px;
        margin-left: -16px;
      }
      @media (max-width: 744px) {
        width: 100%;
        margin-left: 0;
      }

      @media (max-width: 640px) {
        padding: 0;
        border: none;
      }
    }

    &__car-type {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      margin-top: 20px;
      &__item {
        padding: 8px 3px;
        border: 0.5px solid #e9e9e9;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 4px;
        margin-top: 4px;
        @include transition;
        &:hover {
          border: 0.5px solid #cdddff;
          p {
            color: #074ce7;
          }
        }
        &.active,
        &.body-item-active {
          background-color: #e6edfd;
          border: 0.5px solid #e6edfd;
        }
        &:last-child {
          margin-right: 0;
        }
        &__value {
          color: $dark-900;
          font-size: 12px;
          line-height: 14.52px;
          text-align: center;
          @media (max-width: 960px) {
            font-size: 10px;
            line-height: 12.1px;
          }
        }
        img {
          width: 92px;
          height: 56px;
        }
        @media (max-width: 960px) {
          height: 84px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0;
        }
      }
      @media (max-width: 960px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 640px) {
        display: none;
      }
    }

    &__dropdowns {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 24px;
      padding-right: 12px;
      @media (max-width: 640px) {
        display: none;
      }
      .dropdown {
        width: 100%;
        .btn {
          color: $dark-900;
          font-size: 14px;
          line-height: 18.2px;
          border: 1px solid #e9e9e9;
          border-radius: 4px;
          padding: 12px;
          width: 100%;
          @include transition;
          &:disabled {
            opacity: 0.5;
            background-color: transparent;
          }

          &.show {
            color: #074ce7;
            background-color: #e7eefd;
            .dropdown-active {
              display: block;
              transform: rotate(180deg);
              @include transition;
            }
            .dropdown-chevron {
              display: none;
            }
          }
          &.active {
            background-color: transparent;
          }
          &:hover {
            color: #074ce7;
            background-color: #e7eefd;
            .dropdown-active {
              display: block;
              width: 16px;
              height: 16px;
            }
            .dropdown-chevron {
              display: none;
            }
          }
        }
        .dropdown-active {
          display: none;
          width: 16px;
          height: 16px;
        }
        .dropdown-chevron {
          width: 16px;
          height: 16px;
        }
        .dropdown-item {
          padding: 8px 12px;
          @include transition;
          border-radius: 8px;
          color: #1c1c24;
          font-size: 14px;
          line-height: 18.2px;
          &:hover {
            background-color: #e6edfd;
          }
          &.active-item {
            color: #074ce7 !important;
            background-color: transparent;
          }
        }
      }
      .double-dropdown {
        .btn {
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
        }
        .dropdown:nth-of-type(2) .btn {
          border-radius: 0 4px 4px 0;
          border-left: none;
        }
        input {
          width: 100%;
          border: 1px solid #e9e9e9;
          padding: 11px 12px;
          color: $dark-900;
          font-size: 14px;
          line-height: 18.2px;
          &::placeholder {
            color: $dark-900;
            font-size: 14px;
            line-height: 18.2px;
          }
          &:focus {
            background: #edf2fd;
          }
        }
        .dropdown:nth-of-type(1) input {
          border-radius: 4px 0 0 4px;
        }
        .dropdown:nth-of-type(2) input {
          border-left: none;
          border-radius: 0px 4px 4px 0;
        }
      }
    }
    .catalog-select__button {
      @media (max-width: 640px) {
        display: none;
      }
    }
    &__list {
      margin-top: 40px;
      @media (max-width: 960px) {
        margin-top: 89px;
      }
      @media (max-width: 640px) {
        margin-top: 12px;
      }
      .dropdown {
        margin-left: 4px;

        .btn {
          color: $dark-900;
          font-size: 14px;
          line-height: 18.2px;
          border: 1px solid #e9e9e9;
          border-radius: 4px;
          padding: 12px;
          width: 100%;
          @include transition;
          &:hover {
            color: #074ce7;
            background-color: #e7eefd;
            .dropdown-active {
              display: block;
            }
            .dropdown-chevron {
              display: none;
            }
          }
          &.active {
            background-color: transparent;
          }
          &.show {
            color: #074ce7;
            background-color: #e7eefd;
            .dropdown-active {
              display: block;
              transform: rotate(180deg);
              @include transition;
            }
            .dropdown-chevron {
              display: none;
            }
          }
        }
        .dropdown-item {
          &.active,
          &.active-item {
            color: #074ce7 !important;
            background-color: transparent;
          }
        }
        .dropdown-chevron {
          width: 16px;
          height: 16px;
          margin-left: 8px;
        }
        .dropdown-active {
          display: none;
          width: 16px;
          height: 16px;
          margin-left: 8px;
        }
        .dropdown-menu {
          .dropdown-item {
            padding: 8px 12px;
            @include transition;
            border-radius: 8px;
            &:hover {
              background-color: #e6edfd;
            }
          }
        }
      }
      &-line {
        &__params {
          border: 1px solid #e9e9e9;
          border-radius: 4px;
          padding: 8px 12px;
          display: none;
          span {
            color: $dark-900;
            font-size: 14px;
            line-height: 18.2px;
          }
          svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          @media (max-width: 640px) {
            display: flex;
          }
        }
        &__sort {
          &-text {
            color: $dark-900;
            font-size: 14px;
            line-height: 18.2px;
            margin-right: 4px;
            @media (max-width: 640px) {
              display: none;
            }
          }
          .dropdown {
            .btn {
              color: $dark-900;
              font-size: 14px;
              line-height: 18.2px;
              border: 1px solid #e9e9e9;
              border-radius: 4px;
              padding: 8px 12px;
              width: 100%;
              @media (max-width: 640px) {
                padding: 11px 12px;
              }
              .dropdown-chevron {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        &__list-type {
          @media (max-width: 640px) {
            display: none;
          }
          &__text {
            color: $dark-900;
            font-size: 14px;
            line-height: 18.2px;
            margin-right: 8px;
          }
          &__button {
            border: 1px solid #e9e9e9;
            padding: 9px 12px;
            cursor: pointer;
            &:nth-child(2) {
              border-radius: 4px 0 0 4px;
              .column {
                display: none;
              }
              .column-disable {
                display: block;
              }
              &:hover {
                .column {
                  display: block;
                }
                .column-disable {
                  display: none;
                }
              }
            }
            &:nth-child(3) {
              border-radius: 0 4px 4px 0;
              margin-left: -1px;
              max-height: 36px;
              .grid-active {
                display: none;
              }
              &:hover {
                .grid-active {
                  display: block;
                }
                .grid {
                  display: none;
                }
              }
            }

            svg {
              width: 16px;
              height: 16px;
              vertical-align: top;
            }
            @include transition;
            &:hover {
              background-color: #f3f5fc;
            }
          }
          .active {
            border: 1px solid #d3e1fe;
            background: #e6edfd;
            .column {
              display: block;
            }
            .column-disable {
              display: none;
            }
            .grid-active {
              display: block;
            }
            .grid {
              display: none;
            }
          }
        }
      }
    }
  }
  &__cards {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media (max-width: 640px) {
      margin-top: 32px;
    }
    &--column {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;
      @media (max-width: 960px) {
        grid-gap: 24px;
      }
      .recomendation-container__card {
        flex-direction: row;
        // flex-wrap: wrap;
        gap: 0px;
        padding: 23px;
        &.page-request-card {
          flex-direction: column;
        }
        @media (max-width: 960px) {
          flex-wrap: nowrap;
        }
        &__name {
          margin-top: 0;
          font-size: 20px;
          line-height: 24.2px;
        }
        .price--column {
          display: none;
        }
        .price--line {
          display: block;
        }
        &__img {
          margin-right: 40px;
          min-width: 260px;
          height: 180px;
          @media (max-width: 960px) {
            margin-right: 32px;
          }
        }
        &__bottom-notice {
          margin-top: 40px;
          background-color: #ececec;
          padding: 8px 0;
          width: 100%;
          border-radius: 4px;
          svg {
            margin-right: 8px;
            width: 24px;
            height: 24px;
          }
          p {
            color: $dark-900;
            font-size: 12px;
            line-height: 14.52px;
          }
        }
      }
      .recomendation-container__card__cost {
        @media (max-width: 960px) {
          gap: 12px;
        }
      }
    }
    &--row {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      @media (max-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
      }
      @media (max-width: 640px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .recomendation-container__card {
        flex-direction: column;
        &__img {
          @media (max-width: 960px) {
            width: 292px !important;
          }
          @media (max-width: 744px) {
            width: 239.5px !important;
          }
          @media (max-width: 640px) {
            width: 100% !important;
            height: auto !important;
          }
          @media (max-width: 480px) {
            width: 100% !important;
            height: 160px !important;
          }
        }
        &__content {
          @media (max-width: 640px) {
            width: 100%;
          }
        }
        .price--column {
          display: block;
        }
        .price--line {
          display: none;
        }
        &__img {
          margin-right: 0px;
          width: 244px;
          height: 160px;
        }
      }
    }
    .price--column {
      display: none;
    }
  }
  &__offer-col {
    margin-left: auto;
    max-width: 260px;
    &__title {
      color: $dark-900;
      font-weight: 500;
      font-size: 20px;
      line-height: 24.2px;
    }
    &__container {
      .recomendation-container__card {
        flex-direction: column;
        margin-top: 16px;
        &__img {
          width: 228px;
          height: 160px;
        }
        .hourglass {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        &__spec-line {
          background-color: #e6edfd;
          border-radius: 8px;
          padding: 8px 12px;
          margin-top: 16px;
          img {
            margin-right: 7px;
          }
          p {
            text-align: center;
            color: $dark-900;
            font-size: 14px;
            line-height: 16.94px;
          }
        }
        &__text {
          margin-top: 16px;
        }
        &__cost {
          margin-top: 4px;
        }
      }
      &__spec-item {
        margin-top: 16px;
        border: 1px solid #ececec;
        border-radius: 8px;
        padding: 24px 16px;
        &__title {
          color: #002b79;
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
          font-family: "Montserrat Alternates";
        }
        &__text {
          margin-top: 16px;
          color: $dark-900;
          font-size: 14px;
          line-height: 21px;
        }
        &__button {
          background-color: #fff;
          margin-top: 16px;
          color: $dark-900;
          font-size: 14px;
          line-height: 16.94px;
          font-weight: 500;
          border: 1px solid #e9e9e9;
          @include transition;
          width: 100%;
          &:hover {
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
  &__bottom-line {
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    padding: 32px;
    margin-top: 64px;
    @media (max-width: 960px) {
      margin-top: 40px;
    }
    @media (max-width: 375px) {
      padding: 24px;
    }
    &__title {
      color: $dark-900;
      font-family: "Montserrat Alternates";
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }
    &__text {
      margin-top: 16px;
      color: $dark-900;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
.button-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//PERSONAL OFFICE
.personal-office-nav {
  overflow: auto;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 640px) {
    margin: 24px calc((100vw - 449px) / -2) 0;
    width: 100vw;
  }
  @media (max-width: 480px) {
    margin: 24px calc((100vw - 343px) / -2) 0;
  }
  @media (max-width: 375px) {
    margin: 24px calc((100vw - 300px) / -2) 0;
  }
  &__container {
    width: max-content;
    display: flex;
    @media (max-width: 640px) {
      padding: 0 calc((100vw - 449px) / 2);
    }
    @media (max-width: 480px) {
      padding: 0 calc((100vw - 343px) / 2);
    }
    @media (max-width: 375px) {
      padding: 0 calc((100vw - 300px) / 2);
    }
  }
  .catalog-select__nav-item {
    width: max-content;
    @media (max-width: 960px) {
      padding: 16px 24px;
    }
  }
}
.personal-office {
  @media (max-width: 640px) {
    .recomendation-container__card__container-wrapper {
      flex-direction: column;
      width: 100%;
      .recomendation-container__card__img {
        min-width: 100%;
        margin-right: 0;
      }
    }
    .recomendation-container__card__content {
      margin-top: 24px;
    }
    .recomendation-container__card__bottom-notice {
      margin-top: 24px;
      width: 100%;
      background-color: #ececec;
      border-radius: 4px;
      padding: 8px 12px;
      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
  @media (max-width: 480px) {
    .recomendation-container__card__container-wrapper
      .recomendation-container__card__img {
      height: 160px !important;
    }
    .recomendation-container__card__bottom-notice {
      p {
        font-size: 12px;
        line-height: 14.52px;
      }
    }
  }
  &__title {
    color: $dark-900;
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-size: 32px;
    line-height: 41.6px;
    margin-top: 29px;
    @media (max-width: 960px) {
      margin-top: 24px;
    }
  }
  .nav-tabs {
    margin-top: 24px;
    .nav-item {
      border: 1px solid #e9e9e9;
      margin-left: -1px;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      .nav-link {
        padding: 8px 16px;
        color: $dark-900;
        font-size: 14px;
        line-height: 18.2px;
      }
    }
    .active {
      background-color: #edf2fd;
      border: 1px solid #cdddff;
      position: relative;
      z-index: 1;
    }
  }
  &__request {
    max-width: 860px;
    margin-top: 40px;
    .catalog__cards {
      margin-top: 0;
    }
    .recomendation-container__card {
      @media (max-width: 960px) {
        flex-wrap: wrap;
      }
      @media (max-width: 640px) {
        .recomendation-container__card__name {
          margin-top: 0;
        }
      }

      &__img {
        margin-right: 40px;
      }
    }
  }
  &__bid {
    .recomendation-container__card {
      flex-wrap: wrap;
      @media (max-width: 640px) {
        flex-wrap: nowrap;
      }

      &__content-wrapper {
        @media (max-width: 640px) {
          flex-direction: column;
          width: 100%;
        }
      }
      &__cost {
        display: none;
        @media (max-width: 960px) {
          display: flex;
        }
        @media (max-width: 640px) {
          display: none;
        }
      }
      &__cost--title {
        display: none;
        @media (max-width: 960px) {
          display: block;
        }
        @media (max-width: 640px) {
          display: none;
        }
      }
      @media (max-width: 640px) {
        .recomendation-container__card__name {
          margin-top: 0;
        }
      }
      &__time {
        margin-top: 32px;
        padding: 16px;
        background-color: #e6edfd;
        border-radius: 8px;
        max-width: 232px;
        @media (max-width: 960px) {
          margin-top: 24px;
        }
        &--desctop {
          @media (max-width: 960px) {
            display: none;
          }
        }
        &__mobile {
          display: none;
          @media (max-width: 960px) {
            display: flex;
            width: 100%;
            max-width: 100%;
            margin-top: 24px;
          }
        }
        svg {
          margin-right: 7px;
          width: 16px;
          height: 16px;
        }
        p {
          color: $dark-900;
          font-size: 14px;
          line-height: 16.94px;
        }
      }
      &__auction {
        margin-left: auto;
        @media (max-width: 960px) {
          flex-basis: 100%;
          width: 100%;
        }
      }
      &__auction-info {
        margin-left: auto;
        border-radius: 8px;
        padding: 16px 16px 12px 16px;
        min-width: 337px;
        @media (max-width: 1200px) {
          min-width: 218px;
        }
        @media (max-width: 960px) {
          margin-top: 12px;
        }
        &-text {
          color: #454545;
          font-weight: 600;
          font-size: 14px;
          line-height: 16.94px;
        }
        &-price {
          margin-top: 12px;
          color: $dark-900;
          font-weight: 600;
          font-size: 24px;
          line-height: 29.05px;
        }
        &--good {
          background-color: #e6ffed;
        }
        &--bad {
          background-color: rgba(211, 68, 64, 0.1);
        }
        &__bottom-line {
          padding: 16px 16px 12px 16px;
          &__text {
            color: #454545;
            font-size: 14px;
            line-height: 16.94px;
          }
          &__price {
            color: #000000;
            font-size: 14px;
            line-height: 16.94px;
          }
        }
      }
    }
  }
  &__fav {
    max-width: 960px;
    .recomendation-container__card {
      &__name {
        @media (max-width: 640px) {
          margin-top: 0;
        }
      }
      &__fav-btn {
        margin-left: auto;
        padding: 16px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
      }
    }
    @media (max-width: 960px) {
      .recomendation-container__card-fav {
        display: none;
      }
    }
    @media (max-width: 640px) {
      .recomendation-container__card__text {
        display: none;
      }
      .recomendation-container__card__cost {
        margin-top: 24px;
      }
    }
  }
  &__settings {
    .pasww-input {
      margin-top: 2px;
      input {
        margin-top: 0;
      }
    }
    &__title {
      margin-top: 40px;
      color: $dark-900;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 960px) {
        margin-top: 31px;
      }
    }
    &__edit-btn {
      margin-top: 8px;
      color: #074ce7;
      font-size: 14px;
      line-height: 21px;
    }
    &__account-info {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 348px 348px;
      grid-gap: 24px 16px;
      @media (max-width: 960px) {
        grid-template-columns: 343px;
        grid-gap: 16px;
      }
      @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &--pswd {
        grid-template-columns: 348px;
        @media (max-width: 960px) {
          grid-template-columns: 343px;
          grid-gap: 16px;
        }
        @media (max-width: 480px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      &__input {
        &-title {
          color: #33333a;
          font-size: 14px;
          line-height: 21px;
        }
        input {
          margin-top: 2px;
          width: 100%;
          padding: 16px 12px;
          background-color: #f8fafc;
          border-radius: 4px;
          color: $dark-900;
          font-size: 14px;
          line-height: 14px;
          &::placeholder,
          &:disabled {
            color: #a4a4a7;
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
    }
  }
}

// MODAL
.modal-slider {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(33, 33, 33, 0.75);

  &__main-img {
    flex: 1;
    height: 659px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 9px;
    position: sticky;
    top: 10px;
    @media (max-width: 1024px) {
      flex: inherit;
      width: 100%;
      position: static;
      order: 1;
      margin-right: 0;
      height: 520px;
    }
    @media (max-width: 480px) {
      height: 360px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .modal-nav-imgs {
    margin-right: 26px;
    position: sticky;
    top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .swiper-slide {
      border-radius: 8px;
      overflow: hidden;
    }
    @media (max-width: 1024px) {
      position: static;
      order: 2;
      flex-direction: row;
      width: 100%;
      align-items: stretch;
      margin: 12px 0;

      .modal-images {
        height: 77px;
        width: 100%;
      }
    }
    .modal-top-btn {
      border-radius: 8px 8px 0 0;
      @media (max-width: 1024px) {
        border-radius: 8px 0 0 8px;
      }
    }
    .modal-bot-btn {
      border-radius: 0 0 8px 8px;
      @media (max-width: 1024px) {
        border-radius: 0 8px 8px 0;
      }
    }
    &__btn {
      background-color: #373737;
      width: 100%;
      height: 24px;

      @media (max-width: 1024px) {
        width: 24px;
        height: auto;
        svg {
          transform: rotate(-90deg);
        }
      }

      svg {
        width: 10px;
        height: 4.58px;
      }
    }
  }
  .product-card__content__price-sec {
    max-width: 360px;
    padding: 32px;
    border-radius: 8px;
    height: fit-content;
    min-height: 659px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
      min-height: fit-content;
      order: 3;
      margin: 0 !important;
    }
    &__main-info {
      flex: 1;
    }
  }
  .modal-images {
    height: 595px;
    width: 77px;
    overflow: hidden;
    .modal-img-preview {
      cursor: pointer;
    }
  }
}

.modal-open-slider {
  overflow: hidden;
}

.modal-content-slider {
  margin: 70px auto;
  width: 90%;
  max-width: 1160px;
  position: relative;
  padding: 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 100px;
  }

  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -33px;
    right: -33px;
    cursor: pointer;
    @media (max-width: 1024px) {
      top: -48px;
      right: 0;
    }
  }
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide .modal-img-preview {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

//MODALS
//lightbox
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 412px;
    margin-right: auto;
    margin-left: auto;
  }
}
.modal-header {
  padding: 24px 32px;
  border-bottom: 1px solid #ececec;
}
.modal-content {
  background-color: #fff;
  border-radius: 16px;
  .modal-title {
    width: 119px;
    height: 28px;
    svg {
      width: 119px;
      height: 28px;
    }
  }
}
.modal-body {
  padding: 24px 32px;
}
.lightbox-modal {
  &__text {
    color: #242731;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  .modal-btn {
    width: 100%;
    text-align: center;
    border: 1px solid #e9e9e9;
    padding: 16px 0;
    color: $dark-900;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    border-radius: 4px;
    margin-top: 12px;
    &:nth-child(2) {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
.pasww-input {
  width: 100%;
  background-color: #f8fafc;
  padding: 12px;
  border-radius: 4px;
  input {
    background-color: none;
    padding: 0;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}
.login-modal {
  &__text {
    color: #242731;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  &__link {
    margin-top: 2px;
    color: #074ce7;
    font-size: 14px;
    line-height: 21px;
  }
  &__form {
    margin-top: 24px;

    &__input {
      margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
      &-text {
        color: #6f7482;
        font-size: 14px;
        line-height: 21px;
        span {
          color: #f84b40;
        }
      }
      &-input {
        margin-top: 2px;
        width: 100%;
        background-color: #f8fafc;
        padding: 16px 12px;
        border-radius: 4px;
        &::placeholder {
          color: #a4a4a7;
          font-size: 14px;
          line-height: 21px;
        }
      }

      textarea {
        border: none;
        height: 139px;
        width: 100%;
        resize: none;
        overflow-y: auto;
        overflow-x: hidden;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #a4a4a7;
          font-size: 14px;
          line-height: 21px;
        }
      }
      &-btn {
        margin-top: 2px;
        color: #074ce7;
        font-size: 14px;
        line-height: 21px;
        display: block;
      }
    }
    &__btn {
      background-color: #074ce7;
      margin: 24px 0;
      width: 100%;
      &:hover {
        background-color: #3970ec;
      }
    }
  }
  &__notice {
    p {
      color: #8d8d91;
      font-size: 12px;
      line-height: 16.8px;
      a {
        color: #074ce7;
        @include transition;
        font-size: 12px;
        line-height: 16.8px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

//BREADCURMBS

.container-breadcrumbs {
  max-width: 1440px;
  padding: 11px 14px 0;
  margin: 0 auto;
  .breadcrumbs-btn {
    color: $dark-900;
    font-size: 14px;
    line-height: 16.94px;
    padding: 8px 16px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin-right: 22px;
    @include transition;
    &:hover {
      background-color: #f8f8f8;
    }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
  .breadcrumb {
    margin: 0;
    &-item {
      color: #8f8f8f;
      font-size: 12px;
      display: flex;
      align-items: center;
      line-height: 14.52px;
      a {
        color: #8f8f8f;
        font-size: 12px;
        line-height: 14.52px;
        @include transition;
        &:hover {
          color: #606066;
        }
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

// OFFCANVAS MENU //
.offcanvas {
  color: $dark-900;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  &-header {
    padding: 52px 16px 24px;
    border-bottom: 1px solid #e9e9e9;
    .header__bottom-line__search-sec__search-line {
      padding: 9.5px 12px 9.5px 48px;
      display: flex;
    }
  }
  &-body {
    padding: 0;
    &__top-items {
      padding: 30px 16px 0;
    }
    @media (max-width: 640px) {
      .header__bottom-line__nav__menu .spec-item-fav,
      .header__bottom-line__nav__menu-spec-item:last-child {
        display: block;
        svg {
          margin-right: 5px;
        }
      }
      .header__bottom-line__nav__menu-spec-item a {
        border: 1px solid #e9e9e9;
        padding: 16px;
      }
      .header__bottom-line__nav__menu-spec-item {
        svg {
          margin-right: 5px;
        }
      }
    }
    .header__bottom-line__nav__menu-item {
      margin-top: 12px;
      margin-right: 0;

      span {
        display: block;
      }
    }
    &__catalog {
      margin-top: 30px;
      padding: 0 16px 30px;
      border-bottom: 1px solid #e9e9e9;
      &__title {
        font-family: "Montserrat Alternates";
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
      }
      &__list {
        margin-top: 16px;
        &-item {
          padding: 8px 4px;
          margin-top: 12px;
          a {
            font-weight: 500;
            font-size: 16px;
            line-height: 19.36px;
          }
          :first-child {
            margin-top: 0;
          }
        }
      }
      &__bottom-items {
        margin-top: 24px;
        &__item {
          padding: 8px 4px;
          display: block;
          margin-top: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19.36px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  &-footer__list {
    margin-top: 30px;
    padding: 0 16px 37px;
    &-item {
      padding: 8px 4px;
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 19.36px;
      }
    }
  }
}

//OFFCANVAS-PARAMS
.offcanvas-params.offcanvas-end {
  width: 100%;
}
.offcanvas-params {
  overflow: hidden;
  @media (min-width: 641px) {
    display: none !important;
  }
  .offcanvas-header {
    padding: 44px 16px 16px;
    .btn-close {
      margin-left: 0;
    }
    &__params-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20.8px;
    }
    &__params-reset-button {
      color: #074ce7;
      font-size: 14px;
      line-height: 18.2px;
    }
  }
  .offcanvas-body {
    padding: 0 16px;
    position: relative;
    height: 100%;
    padding-bottom: 137px;
    .catalog-select__dropdowns {
      display: block;
      .dropdown {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .double-dropdown {
        margin-top: 12px;
        .dropdown {
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .filter-after-ot,
    .filter-after-do {
      position: relative;
      input {
        padding-left: 32px;
      }
    }
    .filter-after-ot::after,
    .filter-after-do::after {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #7d7d7d;
      font-size: 14px;
      line-height: 130%;
      font-weight: 400;
    }
    .filter-after-ot::after {
      content: "от";
    }
    .filter-after-do::after {
      content: "до";
    }
    &__params-sec {
      margin-top: 32px;
      &_low {
        margin-top: 16px;
        .catalog-select__dropdowns {
          margin-top: 8px;
        }
      }
      &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19.36px;
        &_low {
          font-size: 14px;
          line-height: 130%;
          font-weight: 400;
        }
      }
      &__item {
        margin-top: 16px;
        .catalog-select__car-type {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 4px;
          &__item {
            width: 100%;
            height: auto;
            min-height: 87px;
            margin: 0;
            &__value {
              font-size: 12px;
              line-height: 14.52px;
            }
          }
        }
      }
    }
    &__button {
      position: fixed;
      left: 16px;
      bottom: 63px;
      z-index: 999;
      max-width: 367px;
      width: 100%;
      @media (max-width: 400px) {
        right: 16px;
        width: auto;
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  background: url('data:image/svg+xml;charset=UTF-8,<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 4L12 9L7 14" stroke="%23ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat center;
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 4px;
  margin-right: 4px;
}

#offcanvasMenu {
  @media (min-width: 961px) {
    display: none !important;
  }
}

.btn-scroll-up {
  height: 48px;
  width: 48px;
  cursor: pointer;
  position: fixed;
  border-radius: 50%;
  right: 32px;
  bottom: 48px;
  background-color: #ffffff;
  box-shadow: 0px 4px 25px 0px rgba(48, 50, 55, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s linear;
  z-index: 350;

  @media (max-width: 480px) {
    right: 16px;
    bottom: 40px;
  }

  &_active {
    opacity: 1;
    pointer-events: all;
  }
  svg {
    height: 24px;
    width: 24px;
  }
}
