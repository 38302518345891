.politics-page {
  min-height: 50vh;
  padding-top: 80px;
  color: #1c1c24;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 960px) {
    font-size: 16px;
  }
  @media (max-width: 480px) {
    padding-top: 60px;
  }

  p,
  li {
    margin-bottom: 16px;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-family: inherit;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 60px;
    line-height: 100%;
    margin-top: 0;
    margin-bottom: 24px;
    max-width: 90%;
    @media (max-width: 960px) {
      font-size: 48px;
    }
    @media (max-width: 640px) {
      font-size: 36px;
    }
  }
  h2 {
    font-size: 48px;
    line-height: 72px;
    @media (max-width: 960px) {
      font-size: 36px;
      line-height: 56px;
    }
    @media (max-width: 640px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  h3 {
    font-size: 36px;
    line-height: 56px;
    @media (max-width: 960px) {
      font-size: 30px;
      line-height: 40px;
    }
    @media (max-width: 640px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  h4 {
    font-size: 30px;
    line-height: 40px;
    @media (max-width: 960px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 640px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  h5 {
    font-size: 24px;
    line-height: 32px;
    @media (max-width: 960px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  h6 {
    font-size: 20px;
    line-height: 32px;
    @media (max-width: 960px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
